import { Routes } from "@angular/router";
import { LoadCourseDetailResolver } from "./services/load-course-detail.resolver";
import { PlatformGuard } from "./services/platform.guard";
import { ActiveLessonResolver } from "./services/active-lesson.resolver";
import { AuthGuard } from "@angular/fire/auth-guard";
import { ViewCoursesResolver } from "./services/view-courses.resolver";
import { redirectIfNotAdmin } from "./services/routing-utils";
import { LatestLessonsResolver } from "./services/latest-lessons.resolver";
import { SubscriptionContentResolver } from "./services/subscription-content.resolver";
import { LoginGuard } from "./login/login.guard";
import { DefaultBrandGuard } from "./services/default-brand.guard";
import { certificateProofResolver } from "./certificate-proof/certificate-proof.resolver";
import { GetTenantByPathParamGuard } from "./services/get-tenant-by-path-param.guard";
import { certificateEmbedResolver } from "./certificate-embed/certificate-embed.resolver";
import { CheckIfHomePageActiveGuard } from "./home/check-if-home-page-active.guard";
import { HomePageContentResolver } from "./services/home-page-content.resolver";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { LoadRoomPostsResolver } from "./community/room-page/load-room-posts.resolver";
import { communityAccessGuard } from "./community/community-access.guard";
import { LoadPostResolver } from "./community/post-page/load-post.resolver";
import { LoadRoomsResolver } from "./community/services/load-rooms.resolver";
import { CommunityComponent } from "./community/community.component";

import { SearchPlatformCustomerComponent } from "./support-tools/search-platform-customer/search-platform-customer.component";
import { FindCouponComponent } from "./support-tools/find-coupon/find-coupon.component";
import { courseEmbedRoutes } from "./course-embed/course-embed-routes";
import { platformLifetimeCouponsRoutes } from "./platform-lifetime-coupons/platform-lifetime-coupons-routes";
import { platformLifetimePlanRoutes } from "./platform-lifetime-plan/platform-lifetime-plan-routes";
import { certificatesRoutes } from "./certificates/certificates-routes";
import { editCoursesRoutes } from "./edit-courses/edit-courses.routes";
import { adminRoutes } from "./admin/admin-routes";
import { getCustomPageResolver } from "./page-builder/resolvers/get-custom-page.resolver";
import { pageEditorDeactivateGuard } from "./page-builder/page-editor/guards/page-editor-deactivate.guard";
import { SignInMethodsResolver } from "./services/sign-in-methods.resolver";
import { customPageActivateGuard } from "./page-builder/page-editor/guards/custom-page-activate.guard";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full",
  },
  {
    path: "edit-email-template",
    loadComponent: () =>
      import("./edit-email-template/edit-email-template.component").then(
        (mod) => mod.EditEmailTemplateComponent
      ),
    canActivate: [PlatformGuard],
  },
  {
    path: "courses/:courseUrl",
    loadComponent: () =>
      import("./course-page/course-page.component").then(
        (mod) => mod.CoursePageComponent
      ),
    resolve: {
      course: LoadCourseDetailResolver,
    },
    canActivate: [PlatformGuard],
  },
  {
    path: "courses/:courseUrl/:sectionSeqNo/lessons/:lessonSeqNo",
    loadComponent: () =>
      import("./watch-course/watch-course.component").then(
        (mod) => mod.WatchCourseComponent
      ),
    resolve: {
      course: LoadCourseDetailResolver,
      activeLesson: ActiveLessonResolver,
    },
    canActivate: [PlatformGuard],
  },
  {
    path: "edit-courses",
    children: editCoursesRoutes,
    canActivate: [PlatformGuard, AuthGuard],
    resolve: {
      courses: ViewCoursesResolver,
    },
    data: {
      authGuardPipe: redirectIfNotAdmin,
    },
  },
  {
    path: "courses",
    loadComponent: () =>
      import("./courses/courses.component").then((mod) => mod.CoursesComponent),
    resolve: {
      courses: ViewCoursesResolver,
    },
    canActivate: [PlatformGuard],
  },
  {
    path: "all-lessons",
    loadComponent: () =>
      import("./all-lessons/all-lessons.component").then(
        (mod) => mod.AllLessonsComponent
      ),
    canActivate: [PlatformGuard],
    resolve: {
      latestLessons: LatestLessonsResolver,
      courses: ViewCoursesResolver,
    },
  },
  {
    path: "subscription",
    loadComponent: () =>
      import("./subscription/subscription.component").then(
        (mod) => mod.SubscriptionComponent
      ),
    canActivate: [PlatformGuard],
    resolve: {
      content: SubscriptionContentResolver,
    },
  },
  {
    path: "subscription-promo",
    loadComponent: () =>
      import("./subscription-promo/subscription-promo.component").then(
        (mod) => mod.SubscriptionPromoComponent
      ),
    canActivate: [PlatformGuard],
    resolve: {
      content: SubscriptionContentResolver,
    },
  },
  {
    path: "course-bundles",
    loadComponent: () =>
      import("./purchase-bundle/purchase-bundle.component").then(
        (m) => m.PurchaseBundleComponent
      ),
    canActivate: [PlatformGuard],
    resolve: {
      courses: ViewCoursesResolver,
    },
  },
  {
    path: "login",
    loadComponent: () =>
      import("./login/login.component").then((mod) => mod.LoginComponent),
    canActivate: [LoginGuard],
  },
  {
    path: "platform-login",
    loadComponent: () =>
      import("./authentication/platform-login/platform-login").then(
        (mod) => mod.PlatformLoginComponent
      ),
    canActivate: [DefaultBrandGuard],
  },
  {
    path: "login-page",
    loadComponent: () =>
      import("./authentication/tenant-login/tenant-login").then(
        (mod) => mod.TenantLoginComponent
      ),
    canActivate: [LoginGuard],
  },
  {
    path: "stripe-connection-request",
    loadComponent: () =>
      import(
        "./stripe-connection-request/stripe-connection-request.component"
      ).then((mod) => mod.StripeConnectionRequestComponent),
    canActivate: [DefaultBrandGuard],
  },
  {
    path: "stripe-connection-response",
    loadComponent: () =>
      import(
        "./stripe-connection-response/stripe-connection-response.component"
      ).then((mod) => mod.StripeConnectionResponseComponent),
    canActivate: [DefaultBrandGuard],
  },

  {
    path: "stripe-redirect",
    loadComponent: () =>
      import("./stripe-redirect-page/stripe-redirect-page.component").then(
        (mod) => mod.StripeRedirectPageComponent
      ),
    canActivate: [PlatformGuard],
  },
  {
    path: "new-team-member",
    loadComponent: () =>
      import("./new-team-member/new-team-member.component").then(
        (mod) => mod.NewTeamMemberComponent
      ),
    canActivate: [PlatformGuard],
  },
  {
    path: "contact",
    loadComponent: () =>
      import("./contact-page/contact-page.component").then(
        (mod) => mod.ContactPageComponent
      ),
    canActivate: [PlatformGuard],
  },
  {
    path: "platform-contact",
    loadComponent: () =>
      import("./platform-contact-page/platform-contact-page.component").then(
        (mod) => mod.PlatformContactPageComponent
      ),
    canActivate: [DefaultBrandGuard],
  },
  {
    path: "admin",
    children: adminRoutes,
    canActivate: [PlatformGuard],
  },
  {
    path: "certificates",
    children: certificatesRoutes,
    canActivate: [PlatformGuard],
  },
  {
    path: "certificate-proof/:tenantId/:courseId/:userId",
    loadComponent: () =>
      import("./certificate-proof/certificate-proof.component").then(
        (m) => m.CertificateProofComponent
      ),
    resolve: {
      certificateProofData: certificateProofResolver,
    },
    canActivate: [GetTenantByPathParamGuard],
  },
  {
    path: "certificate-embed/:tenantId/:courseId/:userId",
    loadComponent: () =>
      import("./certificate-embed/certificate-embed.component").then(
        (m) => m.CertificateEmbedComponent
      ),
    resolve: {
      certificateEmbedData: certificateEmbedResolver,
    },
    canActivate: [GetTenantByPathParamGuard],
  },
  {
    path: "course-embed",
    children: courseEmbedRoutes,
    resolve: {
      courses: ViewCoursesResolver,
    },
  },
  {
    path: "start-free-trial-with-card",
    loadComponent: () =>
      import(
        "./start-free-trial-with-card/start-free-trial-with-card.component"
      ).then((m) => m.StartFreeTrialWithCardComponent),
    canActivate: [PlatformGuard],
  },
  {
    path: "platform-lifetime-coupons",
    children: platformLifetimeCouponsRoutes,
    canActivate: [PlatformGuard],
  },
  {
    path: "platform-lifetime-plan",
    children: platformLifetimePlanRoutes,
    canActivate: [PlatformGuard],
  },
  {
    path: "user-settings/my-account",
    loadComponent: () =>
      import("./student-profile/student-profile.component").then(
        (m) => m.StudentProfileComponent
      ),
    canActivate: [PlatformGuard],
    resolve: {
      signInMethods: SignInMethodsResolver,
      courses: ViewCoursesResolver,
    },
  },
  {
    path: "user-settings/my-certificates",
    loadComponent: () =>
      import("./my-certificates/my-certificates.component").then(
        (m) => m.MyCertificatesComponent
      ),
    canActivate: [PlatformGuard],
    resolve: {
      courses: ViewCoursesResolver,
    },
  },
  {
    path: "user-settings/my-affiliate-program",
    loadComponent: () =>
      import("./my-affiliate-program/my-affiliate-program.component").then(
        (m) => m.MyAffiliateProgramComponent
      ),
    canActivate: [PlatformGuard],
  },
  {
    path: "home",
    loadComponent: () =>
      import("./home/home.component").then((m) => m.HomeComponent),
    canActivate: [PlatformGuard, CheckIfHomePageActiveGuard],
    resolve: {
      content: HomePageContentResolver,
      latestLessons: LatestLessonsResolver,
      courses: ViewCoursesResolver,
    },
  },

  {
    path: "page-builder",
    canActivate: [PlatformGuard],
    resolve: {
      courses: ViewCoursesResolver,
    },
    children: [
      {
        path: "site-pages",
        loadComponent: () =>
          import("./page-builder/site-pages/site-pages.component").then(
            (m) => m.SitePagesComponent
          ),
      },
      {
        path: "editor/:pageUrl",
        resolve: {
          page: getCustomPageResolver,
        },
        loadComponent: () =>
          import("./page-builder/page-editor/page-editor.component").then(
            (m) => m.PageEditorComponent
          ),
        canDeactivate: [pageEditorDeactivateGuard],
      },
    ],
  },

  {
    path: "pages/:pageUrl",
    canActivate: [PlatformGuard, customPageActivateGuard],
    resolve: {
      page: getCustomPageResolver,
      courses: ViewCoursesResolver,
    },
    loadComponent: () =>
      import("./page-builder/custom-page/custom-page.component").then(
        (m) => m.CustomPageComponent
      ),
  },

  {
    path: "community",
    loadComponent: () =>
      import("./community/community.component").then(
        (m) => m.CommunityComponent
      ),
    canActivate: [PlatformGuard],
    resolve: {
      courses: ViewCoursesResolver,
      rooms: LoadRoomsResolver,
    },
    children: [
      {
        path: "",
        redirectTo: "home",
        pathMatch: "full",
      },
      {
        path: "home",
        // will show up under /community/home
        loadComponent: () =>
          import("./community/home/community-home.component").then(
            (m) => m.CommunityHomeComponent
          ),
        canActivate: [communityAccessGuard],
      },
      {
        path: "posts/:postId",
        loadComponent: () =>
          import("./community/post-page/post-page.component").then(
            (m) => m.PostPageComponent
          ),
        resolve: {
          post: LoadPostResolver,
        },
        canActivate: [communityAccessGuard],
      },
      {
        path: "members",
        // will show up under /community/members
        loadComponent: () =>
          import("./community/members/community-members.component").then(
            (m) => m.CommunityMembersComponent
          ),
        canActivate: [communityAccessGuard],
      },
      {
        path: "my-profile",
        // will show up under /community/my-profile
        loadComponent: () =>
          import(
            "./community/members/member-detail/member-detail.component"
          ).then((m) => m.MemberDetailComponent),
        canActivate: [communityAccessGuard],
      },
      {
        path: "settings",
        // will show up under /community/settings
        loadComponent: () =>
          import("./community/settings/community-settings.component").then(
            (m) => m.CommunitySettingsComponent
          ),
        canActivate: [AuthGuard],
        data: {
          authGuardPipe: redirectIfNotAdmin,
        },
      },
      {
        path: "manage-rooms",
        loadComponent: () =>
          import("./community/manage-rooms/manage-rooms.component").then(
            (m) => m.ManageRoomsComponent
          ),
        canActivate: [AuthGuard, communityAccessGuard],
        data: {
          authGuardPipe: redirectIfNotAdmin,
        },
      },
      {
        path: "rooms/:roomId",
        loadComponent: () =>
          import("./community/room-page/room-page.component").then(
            (m) => m.RoomPageComponent
          ),
        resolve: {
          roomPosts: LoadRoomPostsResolver,
        },
        canActivate: [communityAccessGuard],
      },
    ],
  },

  {
    path: "support-tools",
    children: [
      {
        path: "search-customer",
        loadComponent: () =>
          import(
            "./support-tools/search-platform-customer/search-platform-customer.component"
          ).then((m) => m.SearchPlatformCustomerComponent),
      },
      {
        path: "find-coupon",
        loadComponent: () =>
          import("./support-tools/find-coupon/find-coupon.component").then(
            (m) => m.FindCouponComponent
          ),
      },
    ],
    canActivate: [PlatformGuard],
  },

  {
    path: "upgrade-to-unlimited",
    canActivate: [PlatformGuard],
    loadComponent: () =>
      import("./app-sumo/app-sumo.component").then((m) => m.AppSumoComponent),
  },

  {
    path: "analytics",
    loadComponent: () =>
      import("./analytics/analytics.component").then(
        (m) => m.AnalyticsComponent
      ),
    canActivate: [PlatformGuard],
    resolve: {
      courses: ViewCoursesResolver,
    },
    children: [
      {
        path: "",
        redirectTo: "sales",
        pathMatch: "full",
      },
      {
        path: "sales",
        loadComponent: () =>
          import("./analytics/sales/sales.component").then(
            (m) => m.SalesComponent
          ),
        canActivate: [PlatformGuard],
      },
      {
        path: "orders",
        loadComponent: () =>
          import("./analytics/orders/orders.component").then(
            (m) => m.OrdersComponent
          ),
        canActivate: [PlatformGuard],
      },
      {
        path: "engagement",
        loadComponent: () =>
          import("./analytics/engagement/engagement.component").then(
            (m) => m.EngagementComponent
          ),
        canActivate: [PlatformGuard],
      },
    ],
  },

  {
    path: "**",
    loadComponent: () =>
      import("./page-not-found/page-not-found.component").then(
        (m) => m.PageNotFoundComponent
      ),
    canActivate: [PlatformGuard],
  },
];
